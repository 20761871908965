/* .maps path {
  fill: #9ca9c2;
  cursor: pointer;
  stroke: #506086;
}
.maps path:focus,
.maps path:hover {
  fill: #5973a3;
} */


.seyv {
  fill:  #9ca9c2;
  cursor: pointer;
  transition: 0.7s;
  &:hover {
    fill: #7e97ce;
  }
}
.active {
  transition: 0.7s;
  fill: #5973a3;
  cursor: pointer;
}
